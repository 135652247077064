<template>
  <div class="warp">
    <van-form @submit="onSubmit">
    <van-field
      readonly
      clickable
      label-width="100"
      name="选择收运分类"
      :value="categoryNameF"
      label="选择收运分类"
      placeholder="点击选择收运分类"
      @click="showPicker1 = true"
    />
    <van-field
      readonly
      clickable
      label-width="100"
      name="选择收运废物"
      :value="categoryNameZ"
      label="选择收运废物"
      placeholder="点击选择收运废物"
      @click="showPickers"
    />
    <van-popup v-model="showPicker1" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns1"
        @confirm="onConfirm1"
        @cancel="showPicker1 = false"
      />
    </van-popup>
    <van-popup v-model="showPicker2" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns2"
        @confirm="onConfirm2"
        @cancel="showPicker2 = false"
      />
    </van-popup>
    <van-radio-group v-model="query.unitType" direction="horizontal">
      <p class="jffs">计费方式</p>
      <van-radio :name="1" v-if="categoryNameF != '不可燃固废'">重量(焚烧)</van-radio>
      <van-radio :name="2" v-if="categoryNameF != '不可燃固废'">体积(焚烧)</van-radio>
      <van-radio :name="3" v-if="categoryNameF != '可燃固废'">重量(填埋)</van-radio>
      <van-radio :name="4" v-if="categoryNameF != '可燃固废'">体积(填埋)</van-radio>
    </van-radio-group>
    <van-field
      label-width="100"
      v-model="query.weightValue"
      name="实际重量"
      label="实际重量(吨)"
      type="number"
      placeholder="请输入实际重量(吨)"
      :rules="[{ required: true, message: '请填写实际重量(吨)' }]"
    />
    <van-field
      label-width="100"
      v-if="query.unitType === 2 || query.unitType === 4"
      v-model="query.volumeValue"
      name="实际体积"
      label="实际体积"
      type="number"
      placeholder="请输入实际体积(m³)"
      :rules="[{ required: true, message: '请填写实际体积(m³)' }]"
    />
    <div class="btn">
      <van-button round block type="info" native-type="submit">保存</van-button>
    </div>
    </van-form>
    <div class="btn">
      <van-button round block type="danger" v-if="isShowbtndel" @click="delClick">删除</van-button>
    </div>
    <div class="btn">
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
    <div class="beizhu">说明：一般固废按重量计算，重量必填；轻物质固废按体积计算时，重量、体积必填。</div>
  </div>
</template>

<script>
import { product, productsave, productdel } from '@/api/qyyorder.js'
import { categoryId } from '@/api/notice.js'
import { Toast } from 'vant'
export default {
  data () {
    return {
      isShowbtndel: true,
      columns1: [],
      columns2: [],
      category: [],
      showPicker1: false,
      showPicker2: false,
      query: {
        id: undefined, // 物品id （存在id为编辑，不存在为新增）
        orderId: undefined, // 订单id
        categoryId: undefined, // 分类id
        categoryName: undefined, // 分类名称(不用传)
        unit: undefined, // 单位
        unitType: 1, // 单位类型 1=重量，2=体积
        volumeValue: undefined, // 体积（立方米）
        weightValue: undefined, // 实际重量
      },
      categoryNameF: undefined,
      categoryNameFId: undefined,
      categoryNameZ: undefined,
      parentId: undefined,
    }
  },
  created() {
    if (this.$route.query.id) {
      this.product()
    } else if (this.$route.query.orderId) {
      this.isShowbtndel = false
      this.query.orderId = this.$route.query.orderId
    }
    this.categoryId()
  },
  methods: {
    product() { // 获取编辑订单
      product(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.query = res.data.data
          this.categoryNameF = res.data.data.categoryName.split(" / ")[0]
          this.categoryNameZ = res.data.data.categoryName.split(" / ")[1]
        }
      })
    },
    categoryId() { // 获取所有分类名称
      const id = 0
      categoryId(id).then(res => {
        this.category = res.data.data.children
        this.columns1 = this.category.map(item => {
          return {
            id: item.id,
            text: item.name
          }
        })
      })
    },
    onConfirm1(value) { // 夫分类
      this.categoryNameF = value.text
      this.categoryNameFId = value.id
      this.query.categoryId = undefined
      this.showPicker1 = false;
      this.categoryNameZ = ''
    },
    showPickers() {
      this.showPicker2 = true
        if (this.query.categoryId) {
          categoryId(this.query.categoryId).then(res => {
            this.parentId = res.data.data.parentId
            this.category.forEach(item => {
              if (item.id == this.parentId) {
                this.columns2 = item.children.map(item => {
                  return {
                    id: item.id,
                    text: item.name,
                  }
                })
              } 
            })
          })
        } else {
          this.category.forEach(item => {
            if (item.id == this.categoryNameFId) {
              this.columns2 = item.children.map(item => {
                return {
                  id: item.id,
                  text: item.name,
                }
              })
            } 
          })
        }
    },
    onConfirm2(value) { // 子分类
      this.categoryNameZ = value.text;
      this.query.categoryId = value.id;
      this.showPicker2 = false;
      categoryId(value.id).then(res => {
        if (res.data.code === 200) {
          this.query.categoryName = res.data.data.nameToken
          this.query.unit = res.data.data.unit
        }
      })
    },
    onSubmit(values) {
      if (this.query.unitType === 1 || this.query.unitType === 3) {
        this.query.volumeValue = null
      }
      productsave(this.query).then(res => {
        if (res.data.code === 200) {
          Toast('保存成功')
          this.$router.push({path: 'Orderdetails', query: {id: this.query.orderId, volumeValue: this.query.volumeValue}})
        }
      })
    },
    delClick() { // 删除
      productdel(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          Toast('删除成功')
          this.$router.push({path: 'Orderdetails', query: {id: this.query.orderId}})
        }
      })
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  .jffs {
    width: 100%;
    margin-bottom: 7px;
    color: #646566;
    font-size: 14px;
  }
  .beizhu {
    font-size: 13px;
    text-align: center;
    line-height: 17px;
    margin-top: 30px;
    padding: 0 15px;
  }
  .btn {
    padding: 7.5px 30px;
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-radio-group--horizontal {
  background-color: #fff;
  padding-left: 15px;
  // height: 44px;
  height: 88px;
  align-content: center;
  border-bottom: 1px solid #ebedf0;
  font-size: 14px;
}
::v-deep .van-radio--horizontal {
  margin-left: 10px;
  margin-top: 5px;
}
</style>
